import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { facebookSquare } from "react-icons-kit/fa/facebookSquare"
import { instagram } from "react-icons-kit/fa/instagram"
import NavBar from "../components/NavBar"
import Carousel from "../components/Carousel"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | social media"
        description="Mídias sociais"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-1" />
            <div className="col-span-10">
              <h1>Mídias sociais</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8">
              <p>
                <b>O que fazemos:</b>
              </p>
              <p>abertura de contas</p>
              <p>geração de conteúdo</p>
              <p>gerenciamento de publicidade</p>
              <div className="h-10" />
              <p>
                <b>Abra sua conta</b>
              </p>
              <p>
                Uma vez que você tem o seu site funcionando, você precisa atrair
                tráfego para ele.
              </p>
              <p>
                As redes sociais desempenham um papel importante nesse aspecto e
                é muito recomendável usar com sabedoria.
              </p>
              <p>
                É muito comum que o usuário de um site verifique os links para
                as redes sociais para checar se está "vivo", ou seja, com
                postagens e interações periódicas com os usuários.
              </p>
              <p>
                Do mesmo modo, alguém que encontre sua conta nas redes sociais
                vai visitar seu site para ter uma visão geral e mais informações
                de uma maneira mais formal.
              </p>
              <div className="h-10" />
              <p>
                <b>Mantenha sua conta ativa</b>
              </p>
              <p>
                Nada é pior, em termos de reputação, do que uma conta de rede
                social descuidada.
              </p>
              <p>
                Portanto, você precisa manter uma política de postagem regular.
              </p>
              <p>
                É claro que um post de imagem chama a atenção muito mais do que
                um texto e, se essa imagem mantiver a coesão visual com seu
                site, você está no caminho certo.
              </p>
              <p>
                Nossa equipe é apta a produzir esse tipo de imagens e publicar,
                em seu nome, em suas redes sociais.
              </p>
              <div className="h-10" />
              <p>
                <b>Propaganda</b>
              </p>
              <p>
                Outra possibilidade é anunciar nas redes sociais usando um alto
                nível de detalhes para encontrar um público específico.
              </p>
              <p>
                Existem inúmeras opções de valores e quantidade de impressões e
                podemos ajudá-lo a encontrar um bom equilíbrio entre seu
                orçamento e a visibilidade desejada.
              </p>
              <div className="h-10" />
              <p>
                <b>Algumas contas atualmente atendidas por nós</b>
              </p>
              <div className="h-10" />
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["dauco"])}
                    backgroundColor={false}
                    alt="daucodesigns - Barcelona"
                  />
                  <div className="text-center py-2">
                    <p>daucodesigns</p>
                    <p>Barcelona - Espanha</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/daucodesigns"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/dauco.designs/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["littlel"])}
                    backgroundColor={false}
                    alt="LittleL - Sydney"
                  />
                  <div className="text-center py-2">
                    <p>LittleL</p>
                    <p>Sydney - Australia</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/LittleLMonaValeBurgers"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/littlelmonavale/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["spkbr"])}
                    backgroundColor={false}
                    alt="Speckle Park Brasil"
                  />
                  <div className="text-center py-2">
                    <p>Speckle Park Brasil</p>
                    <p>São Paulo - Brasil</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/SpeckleParkBrasil"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/speckleparkbrasil/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-10" />
          <div className="h-10" />
          <div className="max-w-full">
            <div
              className="col-span-12"
              style={{ margin: "0 auto", maxWidth: "500px" }}
            >
              <Carousel
                images={data.socialMediaFiles.edges}
                alt="social media"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-8" />
      <div className="text-center">
        <span className="text-2xl">
          Vamos trabalhar juntos?
          <br />
          <br />
        </span>
        <Link
          className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
          to="/pt/lets-work-together/?service=social-media"
        >
          vamos fazer um projeto de mídias sociais
        </Link>
      </div>
      <div className="h-10" />
      <Footer lang="pt" />
    </>
  )
}

export default IndexEN

export const query = graphql`
  {
    socialMediaFiles: allFile(
      filter: { relativePath: { regex: "/social-media/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 500)
          }
        }
      }
    }
    dauco: file(relativePath: { eq: "costumers/Dauco.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
    littlel: file(relativePath: { eq: "costumers/LittleL.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
    spkbr: file(relativePath: { eq: "costumers/SPKBR.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
  }
`
